<template>
  <div style="height: inherit">
   <section id="ecommerce-header">
       <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div v-show="totalCount>0" class="search-results">
                {{ totalCount }} {{ $t("results found") }}
              </div>
            </div>
            <div class="view-options d-flex">
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortType"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in types"
                  :key="sortOption.id"
                  @click="changeType(sortOption.id)"
                >
                  {{ sortOption.title }}
                </b-dropdown-item>
              </b-dropdown>

              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div> 
    </section>

    <div class="body-content-overlay" />

    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="title"
              :placeholder="sortType"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <section :class="itemView">
      <b-card
        v-for="product in items"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-link v-if="getType===4" :to="{ name: 'author', params: { id: product.id } }">
            <b-img
              fluid
              class="image-book card-img-top"
              :src="getImage(product.image)"
            />
          </b-link>
          <b-link v-else :to="{ name: 'book-details', params: { id: product.id } }">
            <b-img
              fluid
              class="image-book card-img-top"
              :src="getImage(product.image)"
            />
          </b-link>
        </div>

        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <h6 v-if="product.Size" class="item-price">
                {{ $t("Size") }}: {{ product.size.title }} {{ $t("MB") }}
              </h6>
              <ul v-else class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{ 'ml-25': star - 1 }"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[
                      { 'fill-current': star <= product.rating },
                      star <= product.rating ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
            <div>
              <h6 class="item-price">{{ $t("Size") }}: {{ product.size }} {{ $t("MB") }}</h6>
            </div>
          </div>
          <h6 class="item-name">

            <b-link  v-if="getType===4" :to="{ name: 'author', params: { id: product.id } }"
              class="text-body"
            >
              {{ product.title }}
            </b-link>
            <b-link v-else
              class="text-body"
              :to="{ name: 'book-details', params: { id: product.id } }"
            >
              {{ product.title }}
            </b-link>
            <b-card-text v-if="product.author" class="item-company">
              {{ $t("By") }}:
              <b-link class="ml-25">
                {{ product.author.title }}
              </b-link>
            </b-card-text>
            <b-row>
              <b-col v-if="product.department" cols="12">
                <b-card-text class="item-company">
                  {{$t("belongs To")}}:
                  <b-link @click.prevent="byDepartment(item.department.id)"
                  class="ml-25">
                    {{ product.department.title }}
                  </b-link>
                </b-card-text>
              </b-col>
              <b-col v-if="product.category" cols="12">
                <b-card-text class="item-company">
                  {{$t("Category")}}:
                  <b-link @click.prevent="byCategory(item.category.id)"
                  class="ml-25">
                    {{ product.category.title }}
                  </b-link>
                </b-card-text>
              </b-col>
              <b-col v-if="product.subcategory" cols="12">
                <b-card-text class="item-company">
                  {{$t("Sub Category")}}:
                  <b-link  @click.prevent="bySubCategory(item.subcategory.id)"
                  class="ml-25">
                    {{ product.subcategory.title }}
                  </b-link>
                </b-card-text>
              </b-col>
              <b-col v-if="product.year" cols="12">
                <b-card-text class="item-company">
                  {{$t("Year")}}:
                    {{ product.year }}
                </b-card-text>
              </b-col>
              <b-col v-if="product.part" cols="12">
                <b-card-text class="item-company">
                  {{$t("Part")}}:
                    {{ product.part }}
                </b-card-text>
              </b-col>
            </b-row>
          </h6>
          <div v-if="product.department" class="item-wrapper">
            <div class="item-rating">
            <b-link @click.prevent="byDepartment(item.department.id)">
            <h6 class="item-price">
                {{ product.department.title }}
              </h6>
            </b-link>
              
            </div>
            <div v-if="product.category">
            <b-link @click.prevent="byCategory(item.category.id)">
            <h6 class="item-price">
                {{ product.category.title }}
              </h6>
            </b-link>
            </div>
          </div>
        </b-card-body>

        <div v-if="getType===3" class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">{{ product.size }} MB</h4>
            </div>
          </div>
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="getBook(product.file)"
          >
            <feather-icon
              icon="BookOpenIcon"
              class="mr-50"
              :class="{ 'text-danger': product.isInWishlist }"
            />
            <span>{{$t("Read Now")}}</span>
          </b-button>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="getBook(product.file)"
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span>{{$t("Download Now")}}</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @change="checkCurrent"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from "bootstrap-vue";
import { filesUrl } from "@/main.js";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ShopLeftFilterSidebar from "./sidebar.vue";
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useECommerceShop";
import { useEcommerceUi } from "./useEcommerce";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    // SFC
    ShopLeftFilterSidebar,
  },
  data() {
    return {
      sortType:"عرض كتاب ام مؤلف",
      items: [],
      totalCount: 0,
      department_id: "",
      author_id: "",
      category_id: "",
      type_id: 3,
      title: "",
      perPage: 12,
      currentPage: 0,
      types:[],
      title:""
    };
  },
  mounted() {
    this.gS();
    this.getTypes()
  },
  watch: {
    "$i18n.locale": function (val) {
      console.log(this.$i18n.locale)
      if(this.$i18n.locale=="ar"){
        this.sortType = "عرض كتاب ام مؤلف"
      }else{
        this.sortType = "Book Or Author"
      }
      this.gS();
      this.getTypes()
    },
    getCategory: function (val) {
      this.gS();
    },
    getSubCategory: function (val) {
      this.gS();
    },
    getDepartment: function (val) {
      this.gS();
    },
    getType: function (val) {
      if(val == 4){
        this.getAuthors()
      }else{
      this.gS();
      }
    },
    title: function (val) {
      this.gS();
    },
  },
  computed:{
    getCategory(){
      return this.$store.getters.getCategory;
    },
    getSubCategory(){
      return this.$store.getters.getSub;
    },
    getDepartment(){
      return this.$store.getters.getDepartment;
    },
    getType(){
      return this.$store.getters.getType;
    }
  },
  methods: {
    async getAuthors() {
      await this.axios
        .get(
          `libraries/client?take=${this.perPage}&skip=${this.currentPage - 1}&type_id=4`,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          this.items = [];
          this.items = res.data.items;
        })
    },
    changeType(id){
      this.updateType(id) 
    },
    checkCurrent(){
      setTimeout(() =>this.gS(), 1000);
    },
    async gS() {
      await this.axios
        .get(
          `libraries/client?take=${this.perPage}&skip=${this.currentPage - 1}&title=${this.title}&department_id=${this.getDepartment}&author_id=&subcategory_id=${this.getSubCategory}&category_id=${this.getCategory}&type_id=${this.getType}`,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.items = [];
          this.totalCount = "";
          this.totalCount = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async getTypes(){
      await this.axios
        .get(
          `typies/clint?library=true`,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          console.log(res)
          this.types = [];
          this.types = res.data.items;
        })
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    getBook(path) {
      window.open(`${filesUrl}${path}`, "_blank");
    },
    async downloadFile(path) {
      console.log(path)
      var title = path.replace('boolfiles/','');
      console.log(`${filesUrl}${path}`)
        await this.axios.get(
        `${filesUrl}/${path}`,
        {headers: {
              "Accept-Language": this.$i18n.locale,
              "responseType":"blob",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":"GET",
              "Access-Control-Allow-Headers": "x-requested-with, Content-Type, origin, authorization, accept, client-security-token"
            },}
      ).then((res) => {
        var fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${title}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })    
    },
    updateDepartment(x) {
      this.$store.commit("setDepartment", x);
    },
    updateCategory(x) {
      this.$store.commit("setCategory", x);
    },
    updateSub(x) {
      this.$store.commit("setSub", x);
    },
    updateType(x) {
      this.$store.commit("setType", x);
    },
    updateAuthor(x) {
      this.$store.commit("setAuthor", x);
    },
    reset() {
      this.$store.commit("reset");
    },
    byDepartment(id) {
      this.reset()
      this.updateDepartment(id)
      this.$router.push("/");
    },
    byCategory(id) {
      this.reset()
      this.updateCategory(id)
      this.$router.push("/");
    },
    bySubCategory(id) {
      this.reset()
      this.updateSub(id)
      this.$router.push("/");
    },
    byAuthor(id) {
      this.reset()
      this.updateAuthor(id)
      this.$router.push("/");
    },
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination();

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products, fetchProducts } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      }).then((response) => {
        products.value = response.data.products;
        totalProducts.value = response.data.total;
      });
    };

    fetchShopProducts();

    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.image-book {
  width: 400px;
  height: 300px;
  min-height: 15.85rem;
  }
</style>
